import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Content from "@elements/Content"
import Block from "@global/layout/Block"
import Layout from "@layouts/layout"
import ModuleArea from "@helpers/moduleArea"
import { GatsbyImage } from "gatsby-plugin-image"
import { ProtectedRoute } from "../components/ProtectedRoute"

const Page = ({ data, pageContext: { parent } }) => {
  const { title, seoMetaTags, content, banner } = data.page
  return (
    <Layout parent={parent} title={title}>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {banner && <GatsbyImage image={banner.gatsbyImageData} />}
      <ProtectedRoute>
        <Block padding="double-top" gutters={true} maxWidth="inner">
          <Content html={`<h1>${title}</h1>`} />
        </Block>
        {content && <ModuleArea modules={content} />}
      </ProtectedRoute>
    </Layout>
  )
}

export const query = graphql`
  query pageById($id: String) {
    page: datoCmsPage(id: { eq: $id }) {
      ...ContentModuleArea
      title
      banner {
        gatsbyImageData(imgixParams: { w: "1280", h: "640", fit: "crop" })
      }
      id
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

export default Page
